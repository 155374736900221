<template>
    <div class="hero is-fullheight">
        <div>
            <div class="container">
                <div class="columns">
                    <div class="column is-half is-offset-one-quarter">
                        <h1 class="title">
                            <img src="images/logo.e54da131bbb2727884dd50423707e9bf.svg">
                        </h1>
                        <form v-if="!success && !error">
                            <div class="field">
                                 <div class="control has-icons-left">
                                    <input class="input is-large" type="text" placeholder="Ort"  v-model="form.place">
                                     <span class="icon is-large is-left">
                                         <i class="fas fa-map-location-dot"></i>
                                    </span>
                                </div>
                            </div>
                            
                            <div class="field">
                                <p class="control has-icons-left">
                                    <span class="select is-large is-fullwidth">
                                    <select formControlName="type" v-model="form.type">
                                        <option value="Scheiben eingeworfen">Scheiben eingeworfen </option>
                                        <option value="Spayereien festgestellt">Spayereien festgestellt</option>
                                        <option value="Beleuchtung geht nicht">Beleuchtung geht nicht</option>
                                        <option value="Littering festgestellt">Littering festgestellt</option>
                                        <option value="Vandalismus">Vandalismus</option>
                                        <option value="Sonstiges" default>Sonstiges</option>
                                    </select>
                                    </span>
                                    <span class="icon is-large is-left">
                                    <i class="fas fa-vest-patches"></i>
                                    </span>
                                </p>
                                </div>

                                
                            
                            <div class="field">
                                <div class="control has-icons-left">
                                    <textarea class="textarea is-large" placeholder="Nachricht" v-model="form.message"></textarea>
                                     <span class="icon is-large is-left">
                                        <i class="fas fa-comment"></i>
                                    </span>
                                </div>
                            </div>

                            <div class="file is-boxed is-fullwidth">
                                <label class="file-label">
                                    <input 
                                        class="file-input" 
                                        type="file" 
                                        accept="image/*"                                       
                                        @change="selectImage( $event )"
                                        multiple
                                        />
                                    <span class="file-cta">
                                    <span class="file-icon">
                                        <i class="fas fa-upload"></i>
                                    </span>
                                    <span class="file-label">
                                        Bilder auswählen...
                                    </span>
                                    </span>
                                </label>
                                </div>

                            <div class="columns">                               
                              <div v-for="item, index in preview_list" :key="index">
                                 <div class="column">
                                <figure class="image is-128x128">
                                    <button class="delete"  @click.prevent="removeItem(index)"></button>
                                    <img :src="item"/>
                                </figure>                
                                </div>
                              </div>                                               
                            </div>                            
                            

                            <div class="field mt-2">
                                <div class="control">
                                    <button class="button is-large is-primary is-fullwidth"  @click.prevent="submitForm">Senden</button>
                                </div>
                            </div>
                        </form>

                        <div v-if="success">
                            <div class="notification is-success">
                                <h1 class="title">Vielen Dank!</h1>
                                <p>Ihre Nachricht wurde erfolgreich versendet.</p>
                            </div>
                            <div class="field mt-2">
                                <div class="control">
                                    <button class="button is-large is-primary is-fullwidth"  @click.prevent="reloadPage">Schliessen</button>
                                </div>
                            </div>
                        </div>

                        
                        <div v-if="error">
                            <div class="notification is-danger">
                                <h1 class="title">Fehler!</h1>
                                <p>Ihre Nachricht konnte nicht versendet werden.</p>
                            </div>
                            <div class="field mt-2">
                                <div class="control">
                                    <button class="button is-large is-primary is-fullwidth" @click.prevent="reloadPage">Schliessen</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'PostFormAxios',
    data(){
        return{
            files: [],
            form: {
                place: '',
                type: '',
                message: '', 
                                            
            },    
            preview_list: [],
            image_list: [],
            error: false,
            success: false,
            hasFiles: false,
            ticketProcesssed: false,
            errorMessage: ''       

        }
    },
    methods:{
        previewMultiImage(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while(count --) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index ++;
        }
      }
        },
        
        selectImage(event) {            
            this.files = event.target.files;
            this.previewMultiImage(event);
        },        
        submitForm(){
            
           axios.post(process.env.VUE_APP_API_URL + '/ticket/5', this.form)
                 .then((res) => {
                     //Perform Success Action
                    console.log(res.data);
                    this.ticketProcesssed = true;
                    this.checkForFiles(res.data);
                    this.successSend();
                 })
                 .catch((error) => {
                     // error.response.status Check status code
                     this.errorHappend(error);
                     console.log(error);
                 }).finally(() => {
                     //Perform action in always
                     console.log("finished")
                 });
        },
        checkForFiles(id){            
            if (this.files) {
                this.hasFiles = true;
                Object.keys(this.files).forEach(key => {
                    console.log(this.files[key]);
                    const formData = new FormData()
                    formData.append('file', this.files[key])
                    formData.append('filename', this.files[key].name)
                    console.log(this.files[key].name);
                   axios.post(process.env.VUE_APP_API_URL + '/upload/' +id, formData)
                        .then((res) => {
                            //Perform Success Action
                            console.log(res);                            
                        })
                        .catch((error) => {
                            // error.response.status Check status code
                            console.log(error);
                        }).finally(() => {
                            //Perform action in always
                            console.log("finished")
                        });                    
                });
            }
        },

        errorHappend(error){
            this.error = true;
            this.success = false;
            this.errorMessage = error.response.data.message;
        },      

        successSend(){
            this.error = false;
            this.success = true;           
        }, 

        reloadPage() {
            window.location.reload();
        },

    removeItem(index) {
       console.log(index);
        
    },
    }
}

</script>
